import React, { Suspense } from 'react';

import { Box, GDSBoxProps, GDSStyleObject } from '@leagueplatform/genesis-core';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import {
  ErrorState,
  HEADER_NAV_BAR_DEFAULT_HEIGHT,
  LoadingSpinner,
  ERROR_TYPES,
} from '@xo-health-web/shared-module';
import { AppHeaderNavBar } from 'components/header-nav/app-header-nav-bar.component';
import logoWithText from 'assets/logo-with-text.png';
import { AppFooter } from './app-footer/app-footer.component';

type BasePageProps = GDSBoxProps<'div'> & {
  css?: GDSStyleObject;
  isPrivatePage?: boolean;
};

export const BasePage: React.FC<BasePageProps> = ({
  children,
  isPrivatePage = true,
  css,
  ...boxProps
}) => {
  useScrollTopOnLocationChange();

  const mainContentMinHeight = isPrivatePage
    ? `calc(100vh - ${HEADER_NAV_BAR_DEFAULT_HEIGHT}px)`
    : '100vh';

  return (
    <>
      {isPrivatePage && <AppHeaderNavBar />}
      <Box
        as="main"
        id="main-content"
        css={{
          position: 'relative',
          flex: '1',
          minHeight: mainContentMinHeight,
          overflowX: 'hidden',
          ...css,
        }}
        {...boxProps} // eslint-disable-line
      >
        <Suspense
          fallback={
            <LoadingSpinner logo={logoWithText} altText="XO_HEALTH_LOGO" />
          }
        >
          <ObservabilityErrorBoundary
            fallback={
              <ErrorState
                isFullPage
                errorType={ERROR_TYPES.API_ERROR}
                error={null}
              />
            }
            errorContext={{
              context: {
                Context: { location: 'Base Page ErrorBoundary' },
              },
            }}
          >
            {children}
          </ObservabilityErrorBoundary>
        </Suspense>
      </Box>
      {isPrivatePage && <AppFooter />}
    </>
  );
};
