import React, { useEffect } from 'react';
import { useAuth } from '@leagueplatform/auth-utils';
import { useHistory } from 'react-router-dom';
import { LoadingSpinner } from '@xo-health-web/shared-module';
import logoWithText from 'assets/logo-with-text.png';
// import { buildErrorUrlParams } from 'utils/auth-error-helpers';
// import { AUTH0_ERROR_TYPES } from 'common/constants';
// import { StandaloneAuth } from '@leagueplatform/auth-standalone';

export const AuthSessionContainer = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { isAuthenticated, error } = useAuth({
    redirectToLogInWhenSignedOut: true,
  });

  const history = useHistory();
  // const location = useLocation();

  useEffect(() => {
    if (error) {
      history.push(`/`);
      // TODO: Need Approval first:
      // Redirect to login page if inactivity error occurs
      // if (error.type === AUTH0_ERROR_TYPES.LOGIN_REQUIRED) {
      //   StandaloneAuth.client.loginWithRedirect({
      //     appState: {
      //       redirectPath: `${location.pathname}?${location.search}`,
      //     },
      //   });
      // } else {
      //   // Redirect to landing page if other auth errors occur
      //   const errorUrlParams = buildErrorUrlParams(error);
      //   history.push(`/?${errorUrlParams}`);
      // }
    }
  }, [error, history]);

  if (!isAuthenticated)
    return <LoadingSpinner logo={logoWithText} altText="XO_HEALTH_LOGO" />;

  return children;
};
