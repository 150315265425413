import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  ErrorState,
  LoadingSpinner,
  ERROR_TYPES,
} from '@xo-health-web/shared-module';

import { useOnlineStatus } from '@leagueplatform/web-common';
import League from '@leagueplatform/core';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { HealthJourneyHome } from '@leagueplatform/health-journey';
import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import { RecentClaimsCardList } from '@leagueplatform/claims';
import { MASONRY } from 'common/constants';
import AppRoutes from './components/AppRoutes';
import { config, initializeNonConfigSDKs } from './init-sdk-config';

initializeNonConfigSDKs();

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  MASONRY.MODULE_KEY_CLAIMS_HOMEPAGE,
  MASONRY.CUSTOM_WIDGET_TYPE_CLAIMS,
  RecentClaimsCardList,
);

MasonryWSDriverNodeRenderersRegistry.overrideRenderer(
  MASONRY.MODULE_KEY_HEALTH_JOURNEY,
  MASONRY.CUSTOM_WIDGET_TYPE_HEALTH_JOURNEY,
  HealthJourneyHome,
);

export function App() {
  const { isOffline, isOnline } = useOnlineStatus();
  return (
    <BrowserRouter>
      <CompatRouter>
        <League.LeagueProvider config={config}>
          <ObservabilityErrorBoundary
            fallback={
              <ErrorState
                isFullPage
                errorType={ERROR_TYPES.API_ERROR}
                error={null}
              />
            }
            errorContext={{
              context: { Context: { location: 'Root App ErrorBoundary' } },
            }}
          >
            <Suspense fallback={<LoadingSpinner />}>
              {isOffline && (
                <ErrorState
                  isFullPage
                  errorType={ERROR_TYPES.NO_CONNECTION}
                  hideButton
                  error={null}
                />
              )}
              {isOnline && <AppRoutes />}
              <ReactQueryDevtools />
            </Suspense>
          </ObservabilityErrorBoundary>
        </League.LeagueProvider>
      </CompatRouter>
    </BrowserRouter>
  );
}
