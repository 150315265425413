import { useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Auth0Error, LandingPage } from '@xo-health-web/shared-module';
import { useHistory } from 'react-router-dom';
import landingBackgroundImage from 'assets/landing-page-illustration.jpg';
import landingMobileBackgroundImage from 'assets/landing-page-illustration-mobile.png';
import logoWithText from 'assets/logo-with-text.png';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import { captureAuthenticationError } from 'utils/sentry-helpers';

export const PreLoginLanding = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleError = useCallback((authError: Auth0Error) => {
    captureAuthenticationError(authError);
    // Will need to add checks for modal component implementation when needed
  }, []);

  return (
    <LandingPage
      backgroundImage={landingBackgroundImage}
      backgroundMobileImage={landingMobileBackgroundImage}
      logoImage={logoWithText}
      primaryButton={{
        buttonText: formatMessage({ id: 'STR_SIGN_IN' }),
        onClick: () => history.push(APP_MANAGED_ROUTES.SIGN_IN),
      }}
      onAuthenticated={(appState) =>
        history.push(appState?.redirectPath || APP_MANAGED_ROUTES.HOME)
      }
      onAuthError={handleError}
    />
  );
};
