import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { Box, StackLayout, HeadingText } from '@leagueplatform/genesis-core';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from '@leagueplatform/web-common';
import {
  usePersonalInformation,
  UserProfileForm,
  EditProfilePhoto,
  EditDisplayName,
  FixedFormFooter,
  LoadingSpinner,
} from '@xo-health-web/shared-module';
import { HeroSection } from 'components/hero-section/hero-section.component';
import logoWithText from 'assets/logo-with-text.png';

export const PersonalInformationPage = ({
  showPersonalInformation,
  handleInfoExit,
}: {
  showPersonalInformation: boolean;
  handleInfoExit: () => void;
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  useDocumentTitle(formatMessage({ id: 'PERSONAL_INFO' }));

  const { data, isLoading } = usePersonalInformation();

  if (isLoading) {
    return <LoadingSpinner logo={logoWithText} altText="XO_HEALTH_LOGO" />;
  }

  return (
    <MainPageContainer width="100%">
      {showPersonalInformation ? (
        <Box>
          <StackLayout horizontalAlignment="center" css={{ height: '30%' }}>
            <HeadingText level="2" size="xl" css={{ marginBottom: '$two' }}>
              {formatMessage({ id: 'JUST_A_COUPLE_MORE_STEPS' })}
            </HeadingText>
            <Box
              css={{
                width: '100%',
                maxWidth: 370,
              }}
            >
              <Box
                css={{
                  marginBottom: '$two',
                }}
              >
                <EditProfilePhoto />
                <EditDisplayName />
              </Box>
              <Box>
                <UserProfileForm data={data} />
              </Box>
              <FixedFormFooter
                allowSubmit={showPersonalInformation}
                handleInfoExit={handleInfoExit}
                submitText={formatMessage({ id: 'CONTINUE' })}
              />
            </Box>
          </StackLayout>
        </Box>
      ) : (
        <Box>
          <HeroSection
            title={formatMessage({ id: 'PERSONAL_INFO' })}
            goBack={history.goBack}
          />
          <StackLayout horizontalAlignment="center">
            <Box
              css={{
                width: '100%',
                maxWidth: 370,
              }}
            >
              <Box
                css={{
                  marginBottom: '$two',
                }}
              >
                <EditProfilePhoto />
                <EditDisplayName />
              </Box>
              <UserProfileForm data={data} />
            </Box>
          </StackLayout>
        </Box>
      )}
    </MainPageContainer>
  );
};
