import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';
import {
  CommunicationPreferencesContent,
  LoadingSpinner,
  ErrorState,
  ERROR_TYPES,
} from '@xo-health-web/shared-module';
import { useAdvocateCommunicationPreference } from 'hooks/use-advocate-communication-preference';

export const CommunicationPreferencesPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const {
    data: advocateCommunicationPreference,
    isLoading,
    isSuccess,
  } = useAdvocateCommunicationPreference();

  useDocumentTitle(formatMessage({ id: 'COMMUNICATION_PREFERENCES' }));

  if (isLoading) {
    return (
      <MainPageContainer width="100%">
        <HeroSection
          title={formatMessage({ id: 'COMMUNICATION_PREFERENCES' })}
          goBack={history.goBack}
        />
        <LoadingSpinner />
      </MainPageContainer>
    );
  }

  if (!isSuccess) {
    return (
      <StackLayout
        horizontalAlignment="center"
        css={{
          position: 'absolute',
          height: '100%',
          width: '100%',
        }}
      >
        <ErrorState errorType={ERROR_TYPES.API_ERROR} error={null} />
      </StackLayout>
    );
  }

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'COMMUNICATION_PREFERENCES' })}
        goBack={history.goBack}
      />
      <StackLayout>
        {isSuccess && (
          <CommunicationPreferencesContent
            advocateCommunicationPreference={advocateCommunicationPreference}
          />
        )}
      </StackLayout>
    </MainPageContainer>
  );
};
